import color from '@/utilities/common/color'
import Compressor from 'compressorjs'
import { ExportToCsv } from 'export-to-csv'
import _, { isEmpty, isNil } from 'lodash'

export function uuid () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const idr = (value, decimal = 2, withRpText = false) => {
  const val = (value / 1).toFixed(decimal)
  const formatted = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return withRpText ? `Rp ${formatted}` : formatted
}

export const isTouchDevice = () => {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  const mq = function (query) {
    return window.matchMedia(query).matches
  }
  if ('ontouchstart' in window || window.DocumentTouch) return true
  const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}

export const compressImage = (imageFile, quality = 0.7, maxHeight = 1000, maxWidth = 1000) => {
  return new Promise((resolve, reject) => {
    const isImage = ['image/jpeg', 'image/jpeg', 'image/png'].includes(imageFile.type)
    if (isImage) {
      // eslint-disable-next-line no-new
      new Compressor(imageFile, {
        quality: quality,
        maxHeight: maxHeight,
        maxWidth: maxWidth,
        success (result) {
          resolve(result)
        },
        error (err) {
          console.log(err.message)
          reject(err)
        }
      })
    } else {
      console.log('skip compressing ', imageFile.type)
      resolve(imageFile)
    }
  })
}

export const convertToFormData = (data) => {
  const formData = new FormData()
  buildFormData(formData, data)
  return formData
}

export const buildFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
    })
  } else if (data && Array.isArray(data)) {
    data.forEach((val, index) => {
      buildFormData(formData, data[index], parentKey ? `${parentKey}[${index}]` : data[index])
    })
  } else {
    let value = data == null ? '' : data
    if (typeof value === 'boolean') {
      value = value ? 1 : 0
    }
    formData.append(parentKey, value)
  }
}

export function generateRandomColor () {
  const listColors = Object.values(color.listOfConstantColor)
  const randomIndex = Math.floor(Math.random() * listColors.length)
  return listColors[randomIndex]
}

export function getContrastColor (hexColor) {
  hexColor = hexColor.replace('#', '')
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000
  return (yiq >= 120) ? 'black' : 'white'
}

export function truncate (str, n) {
  return (str.length > n) ? str.substr(0, n - 1) + '...' : str
}

export function isValidEmail (email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export function isValidPhoneNumber (phone) {
  const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  return re.test(phone)
}

export function getClientDeviceName () {
  // browser name
  const nAgt = navigator.userAgent
  let browserName = navigator.appName
  let nameOffset, verOffset
  if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera'
  } else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer'
  } else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome'
  } else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari'
  } else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox'
  } else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
    (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset)
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName
    }
  } else {
    browserName = 'Unknown Browser'
  }

  let OSName = 'Unknown OS'
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows'
  if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS'
  if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX'
  if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux'

  return `${browserName} - ${OSName}`
}

export function abbreviateNumber (value) {
  let newValue = value
  if (value >= 1000) {
    const suffixes = ['', 'k', 'm', 'b', 't']
    const suffixNum = Math.floor(('' + value).length / 3)
    let shortValue = ''
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat((suffixNum !== 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision))
      const dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z \d]+/g, '')
      if (dotLessShortValue.length <= 2) { break }
    }
    if (shortValue % 1 !== 0) shortValue = shortValue.toFixed(1)
    newValue = shortValue + suffixes[suffixNum]
  }
  return newValue
}

export function isValidWhatsappNumber (value) {
  return /^(^\62|62)(\d{3,4}-?){2}\d{3,4}$/.test(value)
}

export function exportToCsv (rows, fileName = 'Exported') {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: fileName,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
    filename: fileName
    // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
  }
  const csvExporter = new ExportToCsv(options)
  csvExporter.generateCsv(rows)
}

export function getSqlQueryParameters (query) {
  const regex = /:([\w.$]+|"[^"]+"|'[^']+')/g
  const result = query.match(regex)
  return _.uniq(_.map(result, item => item.substring(1)))
}

export function generateQueryParams (params) {
  if (!params) return ''
  const queryParams = {}

  Object.keys(params).forEach((key) => {
    const value = params[key]
    let newVal
    if (
      isNil(value) ||
      (Array.isArray(value) && value.length === 0) ||
      value === ''
    ) {
      return
    } else if (value === false) {
      newVal = '0'
    } else if (value === 0) {
      newVal = '0'
    } else if (value === true) {
      newVal = '1'
    } else if (value === 1) {
      newVal = '1'
    } else if (Array.isArray(value)) {
      const firstItem = value[0]
      const isSortModelItem = checkIsSortModelItem(firstItem)
      if (isSortModelItem) {
        newVal = generateSortString(value)
      } else {
        newVal = value.join(',')
      }
    } else {
      newVal = value.toString()
    }
    queryParams[key] = newVal
  })

  return isEmpty(queryParams)
    ? ''
    : `?${new URLSearchParams(queryParams).toString()}`
}

export function checkIsSortModelItem (item) {
  return (
    typeof item === 'object' && !!item && 'colId' in item && 'sort' in item
  )
}

export function generateSortString (sortModel) {
  const listOfSort = []
  sortModel.forEach((item) => {
    const sort = `${item.colId}:${item.sort}`
    listOfSort.push(sort)
  })

  return listOfSort.join(',')
}
