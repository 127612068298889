export default {
  computed: {
    isRekanan () {
      return this.$store.state.auth.user.role.kode === 'RKN'
    }
  },
  methods: {
    $systemParam (key) {
      return this.$store.getters['system/findSystemParamsByKey'](key)
    }
  }
}
