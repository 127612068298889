import handle from '@/utilities/notification/notification-handler'

export default {
  methods: {
    notify (title, message, onClick = null) {
      this.$vs.notify({
        title: title,
        text: message,
        color: 'primary',
        iconPack: 'feather',
        icon: 'icon-x-circle',
        position: 'bottom-right',
        click: onClick
      })
    },

    notifySuccess (message, onClick = null) {
      this.$vs.notify({
        title: 'Sukses!',
        text: message,
        color: 'success',
        iconPack: 'feather',
        icon: 'icon-check-circle',
        position: 'bottom-right',
        click: onClick
      })
    },

    notifyWarning (message, onClick = null) {
      this.$vs.notify({
        title: 'Peringatan!',
        text: message,
        color: 'warning',
        iconPack: 'feather',
        icon: 'icon-alert-triangle',
        position: 'bottom-right',
        click: onClick
      })
    },

    notifyError (message, onClick = null) {
      this.$vs.notify({
        title: 'Error!',
        text: message,
        color: 'danger',
        iconPack: 'feather',
        icon: 'icon-x-circle',
        position: 'bottom-right',
        click: onClick
      })
    },

    notifyErrorUsingDialog (message) {
      this.$dialogNotifier.show({
        type: 'danger',
        message: message
      })
    },

    handleNotification (notification) {
      handle(notification, this)
    }
  }
}
