export default function actionApprovalReceived (notification, vue) {
  const notificationData = notification.data
  const action = notificationData.action
  const approvalType = notificationData.approval_type
  const idRef = notificationData.id_ref

  if (action === 'IN_APP_NAVIGATION') {
    let routeName, routeParams

    if (approvalType === 'PURCHASE-REQUEST') {
      routeName = 'approvals.purchaseRequestApproval.show'
      routeParams = { idPengadaan: idRef }
    }
    if (approvalType === 'PO-KONTRAK') {
      routeName = 'approvals.poKontrakApproval.show'
      routeParams = { idPo: idRef }
    }
    if (approvalType === 'PO-TERMIN') {
      routeName = 'approvals.poTerminApproval.show'
      routeParams = { idPoD: idRef }
    }
    if (approvalType === 'SPP-KONTRAK') {
      routeName = 'approvals.spkKontrakApproval.show'
      routeParams = { idSpk: idRef }
    }
    if (approvalType === 'SPP-PROGRESS') {
      routeName = 'approvals.spkProgressApproval.show'
      routeParams = { idProgressUnit: idRef }
    }
    if (approvalType === 'SPP-TERMIN') {
      routeName = 'approvals.spkTerminApproval.show'
      routeParams = { idSpkD: idRef }
    }
    if (approvalType === 'SPP-ADJUST') {
      routeName = 'approvals.spkAdjustApproval.show'
      routeParams = { idSpkAdjust: idRef }
    }
    if (approvalType === 'SKUP-CUSTOMER-KONTRAK') {
      routeName = 'approvals.skupApproval.show'
      routeParams = { idBooking: idRef }
    }
    if (approvalType === 'PAYMENT-REQUEST') {
      routeName = 'approvals.paymentRequestApproval.show'
      routeParams = { idPaymentRequest: idRef }
    }
    if (approvalType === 'REIMBURSE' || approvalType === 'REIMBURSE-HRGA' || approvalType === 'REIMBURSE-OPERATIONAL') {
      routeName = 'approvals.reimburseApproval.show'
      routeParams = { idReimburse: idRef }
    }
    if (approvalType === 'KOMISI') {
      routeName = 'approvals.komisiApproval.show'
      routeParams = { idKomisiPayment: idRef }
    }
    if (approvalType === 'IZIN-WARGA') {
      routeName = 'approvals.perijinanWargaApproval.show'
      routeParams = { idPerijinanWarga: idRef }
    }
    if (approvalType === 'CASH-ADVANCE') {
      routeName = 'approvals.cashAdvanceApproval.show'
      routeParams = { idCashAdvance: idRef }
    }
    if (approvalType === 'PENGAJUAN-MEDIA') {
      routeName = 'approvals.pengajuanMediaApproval.show'
      routeParams = { idPengajuanMedia: idRef }
    }

    // route
    const currentRoutePath = vue.$router.currentRoute.path
    const toRoutePath = vue.$router.resolve({
      name: routeName,
      params: routeParams
    }).href

    // navigate or warning
    if (currentRoutePath === toRoutePath) {
      vue.notify('Halaman sama', 'Anda sedang berada pada halaman yang dimaksud.')
    } else {
      vue.$router.push({ path: toRoutePath })
    }
  }
}
