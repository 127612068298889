export default function actionPublishMediaCompleted (notification, vue) {
  if (notification.data.action === 'IN_APP_NAVIGATION') {
    const notificationData = notification.data
    const idRef = notificationData.id_ref

    // route
    const routeName = 'approvals.purchaseRequestApproval.show'
    const routeParams = { idPengadaan: idRef }
    const currentRoutePath = vue.$router.currentRoute.path
    const toRoutePath = vue.$router.resolve({
      name: routeName,
      params: routeParams
    }).href

    // navigate or warning
    if (currentRoutePath === toRoutePath) {
      vue.notify('Halaman sama', 'Anda sedang berada pada halaman yang dimaksud.')
    } else {
      vue.$router.push({ path: toRoutePath })
    }
  } else if (notification.data.action === 'OPEN_EXTERNAL_LINK') {
    window.open(notification.data.url, '_blank').focus()
  }
}
