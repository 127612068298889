import httpClient from '@/utilities/http/http-client'
import httpClientNoInterceptors from '@/utilities/http/http-client-no-interceptors'

const endpoint = ''

export default {
  getCsrfCookie () {
    return httpClient.get(`${endpoint}/sanctum/csrf-cookie`)
  },

  getAuthData () {
    return httpClient.get('api/v1/general/auth/authData')
  },

  changePassword (params) {
    return httpClient.post('api/v1/general/auth/changePassword', params)
  },

  login (username, password) {
    return httpClient.post(`${endpoint}/login`, { username, password })
  },

  logout (params) {
    return httpClient.post(`${endpoint}/logout`, params)
  },

  authenticateBroadcasting (params) {
    return httpClientNoInterceptors.post('/api/broadcasting/auth', params)
  }
}
