import acl from '@/utilities/acl/acl'

export default {
  methods: {
    $can (requestPermissions) {
      return acl.can(requestPermissions)
    },
    $canAny (requestPermissions) {
      return acl.canAny(requestPermissions)
    },
    $cannot (requestPermissions) {
      return acl.cannot(requestPermissions)
    },
    $cannotAny (requestPermissions) {
      return acl.cannotAny(requestPermissions)
    }
  }
}
