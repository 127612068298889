import themeConfig from '@/config/theme'
import navbarSearchAndPinList from '@/constant/navbar-search-and-pin-list'
import { isTouchDevice } from '@/utilities/common/global-methods'
import lsk from '@/constant/lsk'

export default {
  namespaced: true,

  state: {
    bodyOverlay: false,
    isVerticalNavMenuActive: true,
    isTouchDevice: isTouchDevice(),
    mainLayoutType: themeConfig.mainLayoutType,
    navbarSearchAndPinList: navbarSearchAndPinList,
    reduceButton: themeConfig.sidebarCollapsed,
    verticalNavMenuWidth: themeConfig.verticalNavMenuWidth,
    verticalNavMenuItemsMin: false,
    scrollY: 0,
    starredPages: navbarSearchAndPinList.pages.data.filter((page) => page.is_bookmarked),
    theme: themeConfig.theme,
    themePrimaryColor: themeConfig.themePrimaryColor,
    windowWidth: null
  },

  mutations: {
    TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE (state, value) {
      state.isVerticalNavMenuActive = value
    },

    TOGGLE_REDUCE_BUTTON (state, val) {
      localStorage.setItem(lsk.sidebarCollapsed, val)
      state.reduceButton = val
    },

    UPDATE_MAIN_LAYOUT_TYPE (state, val) {
      localStorage.setItem(lsk.mainLayoutType, val)
      state.mainLayoutType = val
    },

    UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN (state, val) {
      state.verticalNavMenuItemsMin = val
    },

    UPDATE_VERTICAL_NAV_MENU_WIDTH (state, width) {
      localStorage.setItem(lsk.verticalNavMenuWidth, width)
      state.verticalNavMenuWidth = width
    },

    UPDATE_STARRED_PAGE (state, payload) {
      const index = state.navbarSearchAndPinList.pages.data.findIndex((item) => item.url === payload.url)
      state.navbarSearchAndPinList.pages.data[index].is_bookmarked = payload.val
      if (payload.val) {
        state.starredPages.push(state.navbarSearchAndPinList.pages.data[index])
      } else {
        const index = state.starredPages.findIndex((item) => item.url === payload.url)
        state.starredPages.splice(index, 1)
      }
    },

    ARRANGE_STARRED_PAGES_LIMITED (state, list) {
      const starredPagesMore = state.starredPages.slice(10)
      state.starredPages = list.concat(starredPagesMore)
    },

    ARRANGE_STARRED_PAGES_MORE (state, list) {
      let downToUp = false
      const lastItemInStarredLimited = state.starredPages[10]
      const starredPagesLimited = state.starredPages.slice(0, 10)
      state.starredPages = starredPagesLimited.concat(list)
      state.starredPages.slice(0, 10).map((i) => {
        if (list.indexOf(i) > -1) downToUp = true
      })
      if (!downToUp) {
        state.starredPages.splice(10, 0, lastItemInStarredLimited)
      }
    },

    TOGGLE_CONTENT_OVERLAY (state, val) {
      state.bodyOverlay = val
    },

    UPDATE_PRIMARY_COLOR (state, val) {
      localStorage.setItem(lsk.themePrimaryColor, val)
      state.themePrimaryColor = val
    },

    UPDATE_THEME (state, val) {
      state.theme = val
    },

    UPDATE_WINDOW_WIDTH (state, width) {
      state.windowWidth = width
    },

    UPDATE_WINDOW_SCROLL_Y (state, val) {
      state.scrollY = val
    }
  },

  getters: {
    windowBreakPoint: state => {
      if (state.windowWidth >= 1200) {
        return 'xl'
      } else if (state.windowWidth >= 992) {
        return 'lg'
      } else if (state.windowWidth >= 768) {
        return 'md'
      } else if (state.windowWidth >= 576) {
        return 'sm'
      } else {
        return 'xs'
      }
    },

    scrollbarTag: state => {
      return state['theme/isTouchDevice'] ? 'div' : 'VuePerfectScrollbar'
    }
  },

  actions: {
    updateVerticalNavMenuWidth ({ commit }, width) {
      commit('UPDATE_VERTICAL_NAV_MENU_WIDTH', width)
    },

    updateStarredPage ({ commit }, payload) {
      commit('UPDATE_STARRED_PAGE', payload)
    },

    arrangeStarredPagesLimited ({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_LIMITED', list)
    },

    arrangeStarredPagesMore ({ commit }, list) {
      commit('ARRANGE_STARRED_PAGES_MORE', list)
    },

    toggleContentOverlay ({ commit }) {
      commit('TOGGLE_CONTENT_OVERLAY')
    },

    updateTheme ({ commit }, val) {
      localStorage.setItem(lsk.theme, val)
      commit('UPDATE_THEME', val)
    }
  }
}
