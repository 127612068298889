import actionApprovalReceived from '@/utilities/notification/actions/approval-received'
import actionPublishMediaCompleted from '@/utilities/notification/actions/publish-media-completed'

export default function handle (notification, vueInstance) {
  switch (notification.data.type) {
    case 'PUBLISH_MEDIA_COMPLETED':
      actionPublishMediaCompleted(notification, vueInstance)
      break
    case 'APPROVAL_RECEIVED':
      actionApprovalReceived(notification, vueInstance)
      break
    default:
      break
  }
}
