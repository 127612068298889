import DialogNotifierComponent from '@/views/components/dialog-notifier/DialogNotifier'

const DialogNotifier = {
  install (Vue, options) {
    this.EventBus = new Vue()

    // register vue global component
    Vue.component(DialogNotifierComponent.name, DialogNotifierComponent)

    // register vue prototype
    Vue.prototype.$dialogNotifier = {
      show (params) {
        DialogNotifier.EventBus.$emit('show', params)
      }
    }
  }
}

export default DialogNotifier
