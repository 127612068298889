import Vue from 'vue'
import Vuesax from 'vuesax'
import lsk from '@/constant/lsk'

export const colors = {
  primary: '#7367F0',
  success: '#28C76F',
  danger: '#EA5455',
  warning: '#FF9F43',
  dark: '#1E1E1E'
}

const config = {
  themePrimaryColor: localStorage.getItem(lsk.themePrimaryColor) || colors.primary,
  disableCustomizer: (JSON.parse(localStorage.getItem(lsk.disableCustomizer)) === true) || false, // true, false(default)
  disableThemeTour: (JSON.parse(localStorage.getItem(lsk.disableThemeTour)) === true) || false, // true, false(default)
  footerType: localStorage.getItem(lsk.footerType) || 'static', // static(default) / sticky / hidden
  hideScrollToTop: (JSON.parse(localStorage.getItem(lsk.hideScrollToTop)) === true) || false, // true, false(default)
  mainLayoutType: localStorage.getItem(lsk.mainLayoutType) || 'vertical', // vertical(default) / horizontal
  navbarColor: localStorage.getItem(lsk.navbarColor) || '#fff', // hex color / rgb / rgba / valid html color name - (default: #fff)
  navbarType: localStorage.getItem(lsk.navbarType) || 'floating', // floating(default) / static / sticky / hidden
  routerTransition: localStorage.getItem(lsk.routerTransition) || 'zoom-fade', // zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
  rtl: (JSON.parse(localStorage.getItem(lsk.rtl)) === true) || false, // true, false(default)
  sidebarCollapsed: (JSON.parse(localStorage.getItem(lsk.sidebarCollapsed)) === true) || false, // true, false(default)
  theme: localStorage.getItem(lsk.theme) || 'light', // light (default), dark, semi-dark
  verticalNavMenuWidth: localStorage.getItem(lsk.verticalNavMenuWidth) || 'default'
}

Vue.use(Vuesax, {
  theme: { colors },
  rtl: config.rtl
})

export default config
