import _ from 'lodash'

export default {
  namespaced: true,

  state: {
    system: JSON.parse(localStorage.getItem('systemData'))
  },

  getters: {
    findSystemParamsByKey: (state) => (keyName) => {
      if (!state.system.systemParameters) return undefined
      const itemParam = _.find(state.system.systemParameters, item => item.key.toString() === keyName.toString())
      if (!itemParam) return undefined
      const type = itemParam.type
      const value = itemParam.value
      if (type === 'string') return value.toString()
      if (type === 'int') return parseInt(value)
      if (type === 'double' || type === 'float') return parseFloat(value)
      if (type === 'boolean') return value === 'true'
      return value
    }
  },

  mutations: {
    SET_SYSTEM_DATA (state, payload) {
      state.system = payload
      localStorage.setItem('systemData', JSON.stringify(payload))
    }
  },

  actions: {}
}
