import router from '@/routes/router'

export default {
  namespaced: true,

  state: {
    user: JSON.parse(localStorage.getItem('userData'))
  },

  getters: {
    isAuthenticated: state => !!state.user
  },

  mutations: {
    SET_USER_DATA (state, payload) {
      state.user = payload
      localStorage.setItem('userData', JSON.stringify(payload))
    },

    CLEAR_USER_DATA () {
      localStorage.removeItem('userData')
      window.location.href = router.resolve({ name: 'login' }).href
    }
  },

  actions: {}
}
