import Vue from 'vue'
import App from '@/App'
import AuthRepository from '@/repositories/general/auth-repository'

// vuesax framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css'

// theme config
import '@/config/theme'

// register global components
import '@/./global-components'

// register global mixins
import '@/./global-mixins'

// scss
import '@/assets/scss/main.scss'

// tailwind
import '@/assets/css/main.css'

// vuex store
import store from '@/store/store'

// vue router
import router from '@/routes/router'

// vue filters
import '@/utilities/filters/filters'

// Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'

// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'

// Leaflet
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'

// vue signature
import VueSignaturePad from 'vue-signature-pad'

// dialog notifier
import DialogNotifier from '@/utilities/plugins/dialog-notifier/dialog-notifier'

// iframe modal
import IframeModal from '@/utilities/plugins/iframe-modal/iframe-modal'

// cue toast notification
import Toast from 'vue-toastification'
import '@/assets/css/vue-toastnotification.css'
import './registerServiceWorker'

// ag grid
import { LicenseManager } from '@ag-grid-enterprise/core'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import '@/views/components/ag-grid/styles/ag-grid-theme.css'
LicenseManager.setLicenseKey(atob(process.env.VUE_APP_AG_GRID_LICENSE))

// leaflet icons
delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

require('./assets/css/iconfont.css')
Vue.use(Vuesax)
Vue.use(VueHammer)
Vue.use(DialogNotifier)
Vue.use(IframeModal)
Vue.use(Toast)
Vue.use(VueSignaturePad)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
  created () {
    // get latest remote data every reload
    if (this.$store.getters['auth/isAuthenticated']) {
      this.refreshData()
    }
  },
  methods: {
    refreshData () {
      AuthRepository.getAuthData()
        .then(response => {
          this.$store.commit('auth/SET_USER_DATA', response.data.data.userData)
          this.$store.commit('system/SET_SYSTEM_DATA', response.data.data.systemData)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}).$mount('#app')
