import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import acl from '@/utilities/acl/acl'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return {
      x: 0,
      y: 0
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('userData')

  // check no auth (the user access backoffice url but not authenticated, then redirect to login)
  if (to.matched.some(record => record.meta.authRequired) && !isAuthenticated) {
    next({ name: 'login' })
  }

  // check already auth (the user access login url but already authenticated, then redirect to dashboard)
  if (to.matched.some(record => record.meta.onlyGuest) && isAuthenticated) {
    next({ name: 'dashboard' })
  }

  // check if required permissions "can" for this route passed
  if (isAuthenticated && to.meta.can !== undefined && !acl.can(to.meta.can)) {
    next({ name: '403' })
  }

  // check if required permissions "cannot" for this route passed
  if (isAuthenticated && to.meta.cannot !== undefined && !acl.cannot(to.meta.cannot)) {
    next({ name: '403' })
  }

  next()
})

router.afterEach((to, from) => {
  // change title
  if (to.meta && to.meta.title) {
    document.title = `${process.env.VUE_APP_PROJECT_NAME} | ${to.meta.title}`
  }

  // remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
