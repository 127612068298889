import Vue from 'vue'
import VxTooltip from '@/views/components/vx-tooltip/VxTooltip'
import VxCard from '@/views/components/vx-card/VxCard'
import VxList from '@/views/components/vx-list/VxList'
import VxBreadcrumb from '@/views/components/vx-breadcrumb/VxBreadcrumb'
import FeatherIcon from '@/views/components/feather-icon/FeatherIcon'
import VxInputGroup from '@/views/components/vx-input-group/VxInputGroup'
import VsPopupCustom from '@/views/components/vs-popup-custom/VsPopupCustom'
import VsSelectCustom from '@/views/components/vs-select/VsSelect'

// v-select component
import vSelect from 'vue-select'
import VsTabsCustom from '@/views/components/vs-tab-custom/VsTabsCustom'
import VsTabCustom from '@/views/components/vs-tab-custom/VsTabCustom'

Vue.component(VxTooltip.name, VxTooltip)
Vue.component(VxCard.name, VxCard)
Vue.component(VxList.name, VxList)
Vue.component(VxBreadcrumb.name, VxBreadcrumb)
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(VxInputGroup.name, VxInputGroup)
Vue.component(VsPopupCustom.name, VsPopupCustom)
Vue.component(VsTabCustom.name, VsTabCustom)
Vue.component(VsTabsCustom.name, VsTabsCustom)
Vue.component(VsSelectCustom.name, VsSelectCustom)

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'XIcon',
        svgClasses: 'w-4 heads-4 mt-1'
      }
    })
  },
  OpenIndicator: {
    render: createElement => createElement('feather-icon', {
      props: {
        icon: 'ChevronDownIcon',
        svgClasses: 'w-5 heads-5'
      }
    })
  }
})

Vue.component(vSelect)
