import IFrameModalComponent from '@/views/components/i-frame-modal/IFrameModal'

const IFrameModal = {
  install (Vue, options) {
    this.EventBus = new Vue()

    // register vue global component
    Vue.component(IFrameModalComponent.name, IFrameModalComponent)

    // register vue prototype
    Vue.prototype.$iframeModal = {
      show (url) {
        IFrameModal.EventBus.$emit('show', url)
      }
    }
  }
}

export default IFrameModal
