<template>
  <div>
    <vs-popup class="sm:popup-w-30"  title="Ups!" :active="isActive" v-on:update:active="triggerActive($event)">
      <div class="vx-row" v-if="isMessageObject">
        <div class="vx-col w-full">
          <ul class="list-disc list-inside pl-5">
            <li class="list-outside" :class="'text-' + type" v-for="(item, index) in errors" :key="index">
              <span class="text-normal">{{ item }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-row" v-if="isMessageString">
        <div class="vx-col w-full">
          <p class="text-normal">{{ message }}</p>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import DialogNotifier from '@/utilities/plugins/dialog-notifier/dialog-notifier'

export default {
  name: 'DialogNotifier',
  data () {
    return {
      isActive: false,
      type: null,
      message: null
    }
  },
  computed: {
    isMessageString () {
      return typeof this.message === 'string'
    },
    isMessageObject () {
      return typeof this.message === 'object'
    },
    errors () {
      if (this.isMessageString || !this.message) return []
      return Object.values(this.message).flat()
    }
  },
  methods: {
    show (params = null) {
      this.type = params.type
      this.message = params.message
      this.isActive = true
    },

    hide () {
      this.isActive = false
    },

    resetData () {
      Object.assign(this.$data, this.$options.data.call(this))
    },

    triggerActive (isActive) {
      if (!isActive) this.resetData()
      this.isActive = isActive
    }
  },
  beforeMount () {
    DialogNotifier.EventBus.$on('show', (params) => {
      this.show(params)
    })
  }
}
</script>
