import Vue from 'vue'
import Vuex from 'vuex'

import accounting from '@/store/modules/accounting/accounting.store'
import approvals from '@/store/modules/approvals/approvals.store'
import auth from '@/store/modules/auth.store'
import general from '@/store/modules/general/general.store'
import marketing from '@/store/modules/marketing/marketing.store'
import estate from '@/store/modules/estate/estate.store'
import master from '@/store/modules/master/master.store'
import procurement from '@/store/modules/procurement/procurement.store'
import proyek from '@/store/modules/proyek/proyek.store'
import system from '@/store/modules/system.store'
import theme from '@/store/modules/theme.store'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    accounting,
    approvals,
    auth,
    general,
    marketing,
    estate,
    master,
    procurement,
    proyek,
    system,
    theme
  }
})
