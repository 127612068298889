import _ from 'lodash'
import store from '@/store/store'

export default {
  /*
  - Parameter accept string or []string.
  - If parameter array, user should have all permissions that defined. (AND Logic)
  */
  can (requestPermissions) {
    if (typeof requestPermissions === 'string' || Array.isArray(requestPermissions)) {
      if (typeof requestPermissions === 'string') requestPermissions = [requestPermissions]
      const storeAuth = store.state.auth
      if (storeAuth.user && storeAuth.user.permissions) {
        const currentUserPermissions = _.map(storeAuth.user.permissions, (item) => item.name)
        return _.difference(requestPermissions, currentUserPermissions).length === 0
      }
      return false
    } else {
      throw Error('The parameter should be string or array')
    }
  },

  /*
  - Parameter accept []string.
  - User should have at least one of any permissions that defined. (OR Logic)
  */
  canAny (requestPermissions) {
    if (Array.isArray(requestPermissions)) {
      const storeAuth = store.state.auth
      if (storeAuth.user && storeAuth.user.permissions) {
        const currentUserPermissions = _.map(storeAuth.user.permissions, (item) => item.name)
        const differenceCount = _.difference(requestPermissions, currentUserPermissions).length
        return differenceCount < requestPermissions.length
      }
      return false
    } else {
      throw Error('The parameter should be array')
    }
  },

  /*
  - Parameter accept string or []string.
  - If parameter array, user should not have all permissions that defined. (AND Logic)
  */
  cannot (requestPermissions) {
    if (typeof requestPermissions === 'string' || Array.isArray(requestPermissions)) {
      if (typeof requestPermissions === 'string') requestPermissions = [requestPermissions]
      const storeAuth = store.state.auth
      if (storeAuth.user && storeAuth.user.permissions) {
        const currentUserPermissions = _.map(storeAuth.user.permissions, (item) => item.name)
        return requestPermissions.every(reqPermission => !currentUserPermissions.includes(reqPermission))
      }
      return true
    } else {
      throw Error('The parameter should be string or array')
    }
  },

  /*
  - Parameter accept []string.
  - User should not have at least one of any permissions that defined. (OR Logic)
  */
  cannotAny (requestPermissions) {
    if (Array.isArray(requestPermissions)) {
      const storeAuth = store.state.auth
      if (storeAuth.user && storeAuth.user.permissions) {
        const currentUserPermissions = _.map(storeAuth.user.permissions, (item) => item.name)
        return requestPermissions.some(reqPermission => !currentUserPermissions.includes(reqPermission))
      }
      return false
    } else {
      throw Error('The parameter should be array')
    }
  }
}
