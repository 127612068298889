<template>
  <div
    ref="conVsTooltip"
    class="con-vs-tooltip"
    @mouseleave="mouseLeaveX"
    @mouseenter="mouseEnterX"
    @mouseup="destroy">
    <transition name="tooltip-fade">
      <div
        v-show="active"
        ref="vsTooltip"
        :class="[`vs-tooltip-${positionX || position}`,`vs-tooltip-${color}`, {'after-none': noneAfter}]"
        :style="style"
        class="vs-tooltip">
        <h4 v-if="title">{{ title }}</h4>
        {{ text }}
      </div>
    </transition>
    <slot></slot>
  </div>
</template>

<script>
import utils from '@/utilities/common/index'
import color from '@/utilities/common/color'

export default {
  name: 'vx-tooltip',
  props: {
    title: {
      default: null,
      type: [String, Number]
    },
    text: {
      default: null,
      type: [String, Number]
    },
    color: {
      default: null,
      type: String
    },
    position: {
      default: 'top',
      type: String
    },
    delay: {
      default: '0s',
      type: [Number, String]
    }
  },
  data: () => ({
    cords: {},
    active: false,
    widthX: 'auto',
    positionX: null,
    noneAfter: false
  }),
  computed: {
    style () {
      return {
        left: this.cords.left,
        top: this.cords.top,
        transitionDelay: this.active ? this.delay : '0s',
        background: color.getColor(this.color, 1),
        width: this.widthX
      }
    }
  },
  methods: {
    mouseEnterX () {
      this.active = true
      this.$nextTick(() => {
        utils.insertBody(this.$refs.vsTooltip)
        this.changePosition(this.$refs.conVsTooltip, this.$refs.vsTooltip)
      })
    },
    mouseLeaveX () {
      this.active = false
    },
    changePosition (elxEvent, tooltip) {
      this.noneAfter = false
      this.positionX = null
      const elx = elxEvent.closest('.con-vs-tooltip')
      const scrollTopX = window.pageYOffset || document.documentElement.scrollTop
      let topX = elx.getBoundingClientRect().top + scrollTopX - tooltip.clientHeight - 4
      let leftX = elx.getBoundingClientRect().left - tooltip.clientWidth / 2 + elx.clientWidth / 2
      const widthX = elx.clientWidth

      if (this.position === 'bottom') {
        topX = elx.getBoundingClientRect().top + scrollTopX + elx.clientHeight + 4
      } else if (this.position === 'left') {
        leftX = elx.getBoundingClientRect().left - tooltip.clientWidth - 4
        topX = elx.getBoundingClientRect().top + scrollTopX + (elx.clientHeight / 2) - (tooltip.clientHeight / 2)
        if (Math.sign(leftX) === -1) {
          leftX = elx.getBoundingClientRect().left
          topX = elx.getBoundingClientRect().top + scrollTopX + elx.clientHeight + 4
          this.positionX = 'bottom'
          this.noneAfter = true
        }
      } else if (this.position === 'right') {
        leftX = elx.getBoundingClientRect().left + elx.clientWidth + 4
        topX = elx.getBoundingClientRect().top + scrollTopX + (elx.clientHeight / 2) - (tooltip.clientHeight / 2)
        if (window.innerWidth - (leftX + tooltip.clientWidth) <= 20) {
          leftX = elx.getBoundingClientRect().left - tooltip.clientWidth / 2 - 10
          topX = elx.getBoundingClientRect().top + scrollTopX + elx.clientHeight + 4
          this.positionX = 'bottom'
          this.noneAfter = true
        }
      }
      this.cords = {
        left: `${leftX}px`,
        top: `${topX}px`,
        width: `${widthX}px`
      }
    },
    destroy () {
      this.active = false
      this.$nextTick(() => {
        if (this.active) {
          utils.removeBody(this.$refs.vsTooltip)
        }
      })
    }
  }
}
</script>
