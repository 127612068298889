export default {
  insertBody (elx) {
    document.body.insertBefore(elx, document.body.firstChild)
  },

  removeBody (element) {
    const bodyX = document.body
    bodyX.removeChild(element)
  },

  changePosition (elx, content, conditional) {
    let topX = 0
    let leftX = 0
    let widthX = 0
    const scrollTopX = window.pageYOffset || document.documentElement.scrollTop
    if (elx.getBoundingClientRect().top + 300 >= window.innerHeight) {
      setTimeout(() => {
        if (conditional) {
          topX = (elx.getBoundingClientRect().top - content.clientHeight) + scrollTopX
        } else {
          topX = (elx.getBoundingClientRect().top - content.clientHeight + elx.clientHeight) + scrollTopX
        }
      }, 1)
    } else {
      topX = conditional ? (elx.getBoundingClientRect().top + elx.clientHeight) + scrollTopX + 5 : elx.getBoundingClientRect().top + scrollTopX
    }

    leftX = elx.getBoundingClientRect().left
    widthX = elx.offsetWidth

    return {
      left: `${leftX}px`,
      top: `${topX}px`,
      width: `${widthX}px`
    }
  }
}
