<template>
  <div>
    <vs-popup class="sm:popup-w-70 popup-content-no-padding" :title="iframe.loaded ? 'File Ready' : 'Please wait..'" :active="isActive" v-on:update:active="isActive = $event">
      <div class="w-full h-screen-80">
        <iframe id="myIframe" name="myIframe" :src="iframe.url" title="description" class="w-full h-full border-0" @load="onIFrameLoaded"/>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import IframeModal from '@/utilities/plugins/iframe-modal/iframe-modal'

export default {
  name: 'IFrameModal',
  data () {
    return {
      isActive: false,
      iframe: {
        url: null,
        loaded: false
      }
    }
  },
  methods: {
    show (url) {
      this.iframe.url = url
      this.isActive = true
    },

    onIFrameLoaded () {
      this.iframe.loaded = true
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
      if (!isActive) {
        this.resetData()
      }
    }
  },
  beforeMount () {
    IframeModal.EventBus.$on('show', (url) => {
      this.show(url)
    })
  }
}
</script>
