import axios from 'axios'
import store from '@/store/store'

const baseDomain = process.env.VUE_APP_BACKEND_API_BASE_URL
const baseURL = `${baseDomain}`

const httpClient = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

httpClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.status === 419) {
      localStorage.setItem('isSessionExpired', 'true')
      localStorage.setItem('previousPath', window.location.pathname)
      store.commit('auth/CLEAR_USER_DATA')
    }
    return Promise.reject(error)
  }
)

export default httpClient
