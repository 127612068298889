// lsk = local storage keys

export default {
  // theme
  disableCustomizer: 'disableCustomizer',
  disableThemeTour: 'disableThemeTour',
  footerType: 'footerType',
  hideScrollToTop: 'hideScrollToTop',
  mainLayoutType: 'mainLayoutType',
  navbarColor: 'navbarColor',
  navbarType: 'navbarType',
  routerTransition: 'routerTransition',
  rtl: 'rtl',
  sidebarCollapsed: 'sidebarCollapsed',
  theme: 'theme',
  themePrimaryColor: 'themePrimaryColor',
  verticalNavMenuWidth: 'verticalNavMenuWidth'
}
