export default {
  darken (color, percent) {
    const f = color.split(',')
    const t = percent < 0 ? 0 : 255
    const p = percent < 0 ? percent * -1 : percent
    const R = parseInt(f[0].slice(4))
    const G = parseInt(f[1])
    const B = parseInt(f[2])
    return 'rgb(' + (Math.round((t - R) * p) + R) + ',' + (Math.round((t - G) * p) + G) + ',' + (Math.round((t - B) * p) + B) + ')'
  },

  getColor (colorX, alphaX = 1, defaultX = true) {
    // change color hex to RGB
    if (/^[#]/.test(colorX)) {
      const c = this.hexToRgb(colorX)

      if (alphaX === 1) {
        colorX = `rgb(${c.r},${c.g},${c.b})`
      } else {
        colorX = `rgba(${c.r},${c.g},${c.b},${alphaX})`
      }
    } else if (/^rgba/.test(colorX)) {
      if (colorX.search(/.([0-9]\))$/) === -1 && !defaultX) {
        colorX = colorX.replace(/.?([0-9]\))$/, `${alphaX})`)
      }
    } else if (/^(rgb)/.test(colorX)) {
      // change rgb and rgba
      if (alphaX !== 1) {
        colorX = colorX.replace(/^(rgb)/, 'rgba')
        colorX = colorX.replace(/\)$/, `,${alphaX})`)
      }
    }
    return colorX
  },

  isColor (colorX) {
    const vsColors = ['primary', 'secondary', 'success', 'danger', 'warning', 'dark', 'light']
    return vsColors.includes(colorX)
  },

  RandomColor () {
    function getRandomInt (min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    }

    return `rgb(${getRandomInt(0, 255)},${getRandomInt(0, 255)},${getRandomInt(0, 255)})`
  },

  rColor (colorX, opacity = 1) {
    if (/^[#]/.test(colorX)) {
      const c = this.hexToRgb(colorX)
      colorX = `rgba(${c.r},${c.g},${c.b},${opacity})`
    } else if (/^[rgb]/.test(colorX)) {
      let colorSplit = colorX.split(')')[0]
      if (!/^[rgba]/.test(colorX)) {
        colorSplit.replace('rgb', 'rgba')
        colorSplit += `,${opacity})`
      } else {
        // colorSplit.replace('rgb','rgba')
        colorSplit += ')'
      }
      colorX = colorSplit
    }

    const vsColors = ['primary', 'success', 'danger', 'warning', 'dark']
    if (colorX) {
      if (/[#()]/.test(colorX)) {
        return colorX
      } else {
        if (vsColors.includes(colorX)) {
          return `rgba(var(--${colorX}),${opacity})`
        } else {
          return `rgba(var(--primary),${opacity})`
        }
      }
    } else {
      return `rgba(var(--primary),${opacity})`
    }
  },

  contrastColor (elementX) {
    let c = elementX
    if (/[#]/g.test(elementX)) {
      const rgbX = this.hexToRgb(elementX)
      c = `rgb(${rgbX.r},${rgbX.g},${rgbX.b})`
    }
    const rgb = c.replace(/^(rgb|rgba)\(/, '').replace(/\)$/, '').replace(/\s/g, '').split(',')
    const yiq = ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000
    return yiq >= 128
  },

  setCssVariable (propertyName, value) {
    if (typeof window !== 'undefined') {
      document.documentElement.style.setProperty(propertyName, value)
    }
  },

  hexToRgb (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  },

  getVariable (styles, propertyName) {
    return String(styles.getPropertyValue(propertyName)).trim()
  },

  changeColor (colorInitial) {
    const colors = ['primary', 'success', 'danger', 'warning', 'dark']
    let colorX

    if (colors.includes(colorInitial)) {
      const style = getComputedStyle(document.documentElement)
      colorX = this.getVariable(style, '--' + colorInitial)
    } else {
      if (/[rgb()]/g.test(colorInitial)) {
        colorX = colorInitial.replace(/[rgb()]/g, '')
      } else if (/[#]/g.test(colorInitial)) {
        const rgbX = this.hexToRgb(colorInitial)
        colorX = `${rgbX.r},${rgbX.g},${rgbX.b}`
      } else {
        colorX = '--' + colorInitial
      }
    }
    return colorX
    // this.setCssVariable('--'+clave,colorX)
  },

  listOfConstantColor: {
    red: '#f44336',
    'red darken-1': '#e53935',
    'red darken-2': '#d32f2f',
    'red darken-3': '#c62828',
    'red darken-4': '#b71c1c',
    pink: '#e91e63',
    'pink darken-1': '#d81b60',
    'pink darken-2': '#c2185b',
    'pink darken-3': '#ad1457',
    'pink darken-4': '#880e4f',
    purple: '#9c27b0',
    'purple darken-1': '#8e24aa',
    'purple darken-2': '#7b1fa2',
    'purple darken-3': '#6a1b9a',
    'purple darken-4': '#4a148c',
    'deep-purple': '#673ab7',
    'deep-purple darken-1': '#5e35b1',
    'deep-purple darken-2': '#512da8',
    'deep-purple darken-3': '#4527a0',
    'deep-purple darken-4': '#311b92',
    indigo: '#3f51b5',
    'indigo darken-1': '#3949ab',
    'indigo darken-2': '#303f9f',
    'indigo darken-3': '#283593',
    'indigo darken-4': '#1a237e',
    blue: '#2196f3',
    'blue darken-1': '#1e88e5',
    'blue darken-2': '#1976d2',
    'blue darken-3': '#1565c0',
    'blue darken-4': '#0d47a1',
    'light-blue': '#03a9f4',
    'light-blue darken-1': '#039be5',
    'light-blue darken-2': '#0288d1',
    'light-blue darken-3': '#0277bd',
    'light-blue darken-4': '#01579b',
    cyan: '#00bcd4',
    'cyan darken-1': '#00acc1',
    'cyan darken-2': '#0097a7',
    'cyan darken-3': '#00838f',
    'cyan darken-4': '#006064',
    teal: '#009688',
    'teal darken-1': '#00897b',
    'teal darken-2': '#00796b',
    'teal darken-3': '#00695c',
    'teal darken-4': '#004d40',
    green: '#4caf50',
    'green darken-1': '#43a047',
    'green darken-2': '#388e3c',
    'green darken-3': '#2e7d32',
    'green darken-4': '#1b5e20',
    'light-green': '#8bc34a',
    'light-green darken-1': '#7cb342',
    'light-green darken-2': '#689f38',
    'light-green darken-3': '#558b2f',
    'light-green darken-4': '#33691e',
    lime: '#cddc39',
    'lime darken-1': '#c0ca33',
    'lime darken-2': '#afb42b',
    'lime darken-3': '#9e9d24',
    'lime darken-4': '#827717',
    yellow: '#ffeb3b',
    'yellow darken-1': '#fdd835',
    'yellow darken-2': '#fbc02d',
    'yellow darken-3': '#f9a825',
    'yellow darken-4': '#f57f17',
    amber: '#ffc107',
    'amber darken-1': '#ffb300',
    'amber darken-2': '#ffa000',
    'amber darken-3': '#ff8f00',
    'amber darken-4': '#ff6f00',
    orange: '#ff9800',
    'orange darken-1': '#fb8c00',
    'orange darken-2': '#f57c00',
    'orange darken-3': '#ef6c00',
    'orange darken-4': '#e65100',
    'deep-orange': '#ff5722',
    'deep-orange darken-1': '#f4511e',
    'deep-orange darken-2': '#e64a19',
    'deep-orange darken-3': '#d84315',
    'deep-orange darken-4': '#bf360c',
    brown: '#795548',
    'brown darken-1': '#6d4c41',
    'brown darken-2': '#5d4037',
    'brown darken-3': '#4e342e',
    'brown darken-4': '#3e2723',
    'grey lighten-1': '#bdbdbd',
    grey: '#9e9e9e',
    'grey darken-1': '#757575',
    'grey darken-2': '#616161',
    'grey darken-3': '#424242',
    'grey darken-4': '#212121'
  }
}
